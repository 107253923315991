@keyframes c1_t { 0% { transform: translate(14px,14px) rotate(-180deg); } 100% { transform: translate(14px,14px) rotate(180deg); } }
@keyframes c1-2_t { 0% { transform: translate(14px,14px) rotate(-180deg); } 60% { transform: translate(14px,14px) rotate(-87.43deg); } 100% { transform: translate(14px,14px) rotate(-87.43deg); } }
@keyframes c1-2_v { 0% { visibility: visible; animation-timing-function: steps(1); } 60% { visibility: hidden; animation-timing-function: steps(1); } 100% { visibility: hidden; animation-timing-function: steps(1); } }
@keyframes c1-2_da { 0% { stroke-dasharray: 7.49px 67.44px; } 6.65% { stroke-dasharray: 7.49px 67.44px; } 55% { stroke-dasharray: 0.75px 74.18px; } 100% { stroke-dasharray: 0.75px 74.18px; } }
@keyframes c1-2_do { 0% { stroke-dashoffset: 0px; } 6.65% { stroke-dashoffset: 0px; } 55% { stroke-dashoffset: -4px; } 100% { stroke-dashoffset: -4px; } }
@keyframes c1-3_t { 0% { transform: translate(14px,14px) rotate(-96deg); } 23.3% { transform: translate(14px,14px) rotate(-96deg); } 83.3% { transform: translate(14px,14px) rotate(-40deg); } 100% { transform: translate(14px,14px) rotate(-40deg); } }
@keyframes c1-3_v { 0% { visibility: hidden; animation-timing-function: steps(1); } 23.3% { visibility: visible; animation-timing-function: steps(1); } 83.3% { visibility: hidden; animation-timing-function: steps(1); } 100% { visibility: hidden; animation-timing-function: steps(1); } }
@keyframes c1-3_da { 0% { stroke-dasharray: 7.49px 67.44px; } 36.65% { stroke-dasharray: 7.49px 67.44px; } 80% { stroke-dasharray: 0.75px 74.18px; } 100% { stroke-dasharray: 0.75px 74.18px; } }
@keyframes c1-3_do { 0% { stroke-dashoffset: 0px; } 36.65% { stroke-dashoffset: 0px; } 80% { stroke-dashoffset: -4px; } 100% { stroke-dashoffset: -4px; } }
@keyframes c1-4_t { 0% { transform: translate(14px,14px) rotate(-36deg); } 40% { transform: translate(14px,14px) rotate(-36deg); } 100% { transform: translate(14px,14px) rotate(-1.78deg); } }
@keyframes c1-4_v { 0% { visibility: hidden; animation-timing-function: steps(1); } 40% { visibility: visible; animation-timing-function: steps(1); } 100% { visibility: hidden; animation-timing-function: steps(1); } }
@keyframes c1-4_da { 0% { stroke-dasharray: 7.49px 67.44px; } 43.3% { stroke-dasharray: 7.49px 67.44px; } 96.65% { stroke-dasharray: 0.75px 74.18px; } 100% { stroke-dasharray: 0.75px 74.18px; } }
@keyframes c1-4_do { 0% { stroke-dashoffset: 0px; } 43.3% { stroke-dashoffset: 0px; } 96.65% { stroke-dashoffset: -4px; } 100% { stroke-dashoffset: -4px; } }
