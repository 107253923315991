
:root {
   --accentB500: #F0DFB4;
   --highlight: #FFF6D8;
}
.ani {
   animation: bgAnimate 3s infinite linear;
}
@keyframes bgAnimate {
   0% {
      background-color: var(--accentB500);
   }
   50% {
      background-color: var(--highlight);
   }
   100% {
      background-color: var(--accentB500);
   }
}

